//Overwrite Bootstrap Colors

$primary: #ee2127;
$secondary: #252262;

$font-size-base: 0.85rem;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Oxygen:wght@300;400;700&display=swap');

// import bootstrap styles at the bottom!
@import '~bootstrap/scss/bootstrap.scss';

@import "~bootstrap-icons/font/bootstrap-icons.css";

$headings-font-weight:        700;