* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Oxygen", sans-serif;
}

.light_text{
  color:  white !important;
}

.white-hover-text:hover{
  color:  white !important;
}
i{
  color: inherit;
}
/* sidbar CSS */

.sidebar-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.sidebar-menu-items {
  width: 100%;
}

.sidebar-toggle {
  background-color: #ffffff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.min_height{
  min-height: 300px;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 60px;
}


.dashboard li.nav-item {
  height: auto;
}

.dashboard .nav-link.active{
  color: #ee2127 !important;
}
.dashboard .nav-link{
  color: #252262 !important;
}
.dark_overlay{
  background-color: rgba(0, 0, 0, 0.328);
  backdrop-filter: blur(24px);
}
.white_overlay{
  background-color: rgba(255, 255, 255, 0.328);
  backdrop-filter: blur(24px);
}

.background_blur{
  backdrop-filter: blur(24px);
}
@media screen and (max-width: 960px) {

}


.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  background-color: limegreen !important;
  border-radius: 50%;
  margin-left: 0.25rem;
  width: 10px;
  height: 10px;
}

/* Initially hide the element */
.hide-element {
  display: none;
  transition: display 0s 3s; /* Delay display for 3 seconds */
}

/* When you want to display the element, remove the class */
.show-element {
  display: block;
  transition: display 0s; /* No transition for immediate display */
}


.invalid {
  background-color: red !important;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}
.dropzone {
  border: 2px dashed #cccccc;
  background: #f8f8f8;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}
.dropBoxArea.form-label {
  display: block;
  padding: 50px;
  text-align: center;
  border-style: dashed !important;
}

.list-image {
  min-height: 260px;
  object-fit: cover;
}

img.previewImages {
  max-width: 80px;
  width: 80px;
  height: 80px;
  margin: 5px;
  object-fit: cover;
  border-radius: 5px;
}

.featured-image {
  min-height: 160px;
  object-fit: cover;
  max-width: 100%;
  min-width: 100%;
  max-height: 160px;
}

img.ys-thumbnail {
  width: 100px;
  height: 80px;
  object-fit: cover;
  margin: 10px 5px;
  border-radius: 5px;
}

img.display-image {
  height: 450px;
  object-fit: contain;
}

@media screen and (max-width: 600px) {
  img.display-image {
    height: 350px;
  }
}